import React from "react"
import { graphql } from "gatsby"
import { IGatsbyImageData } from "gatsby-plugin-image"

import {
  Seo,
  Layout,
  BlogArticlePageContent,
  BlogArticle,
  SectionTextWithImage,
} from "../components"
import { BlogArticleType, PageSectionTextWithImage } from "../types"
import { handleSectionBackground } from "../utils"

interface BlogArticlePageType {
  location: {
    href: string
  }
  data: {
    contentfulPageBlogArticle: {
      topic: {
        name: string
        contentful_id: string
      }
      heading: string
      article: {
        raw: string
      }
      author: {
        name: string
        contentful_id: string
      }
      featuredImage: {
        title: string
        gatsbyImageData: IGatsbyImageData
      }
      createdAt: string
      relatedArticles: Array<BlogArticleType>
      pageSections: Array<PageSectionTextWithImage>
      seo: {
        title: string
        titleTemplate: {
          extension: string
        }
        description: {
          description: string
        }
        image: {
          fixed: {
            src: string
          }
        }
      }
    }
  }
}

const BlogArticlePage = ({ data, location }: BlogArticlePageType) => {
  const page = data.contentfulPageBlogArticle

  return (
    <Layout>
      <Seo
        title={page.seo?.title}
        description={page.seo?.description?.description}
        titleTemplate={page.seo?.titleTemplate?.extension}
        article={true}
        url={location.href}
        image={page?.seo?.image?.fixed?.src}
      />
      <BlogArticlePageContent
        heading={page.heading}
        author={page.author}
        featuredImage={page.featuredImage}
        createdAt={page.createdAt}
        article={page.article}
        topic={page.topic}
        href={location.href}
      />
      {page.pageSections &&
        page.pageSections.length > 0 &&
        page.pageSections.map((section: any, index: number) => (
          <SectionTextWithImage
            key={section.contentful_id}
            contentful_id={section.contentful_id}
            backgroundColour={handleSectionBackground(
              section.backgroundColour,
              index % 2 === 0 ? "grey-lightest" : "white"
            )}
            heading={section.heading}
            content={section.content}
            image={section?.media?.media?.gatsbyImageData}
            imageAlt={section?.media?.media?.title}
            showImageFirst={section.showImageFirst}
            imageBlobBackground={section.imageBlobBackground}
            showSignUpButton={section.showSignUpButton}
            scheduleDemoButton={section.scheduleDemoButton}
            file={section?.media?.media?.file?.url}
            youTubeVideo={section?.media?.youTubeEmbedCode?.youTubeEmbedCode}
          />
        ))}
      {page.relatedArticles && page.relatedArticles.length > 0 && (
        <section className="wrapper-width wrapper-height blog-article-page-related-articles">
          <h3 className="heading-extra-small blog-article-page-related-articles-heading">
            Related articles
          </h3>
          <div className="blog-articles">
            {page.relatedArticles.map((article) => (
              <BlogArticle
                key={article.contentful_id}
                contentful_id={article.contentful_id}
                slug={article.slug}
                topic={article.topic}
                author={article.author}
                featuredImage={article.featuredImage}
                createdAt={article.createdAt}
                heading={article.heading}
              />
            ))}
          </div>
        </section>
      )}
    </Layout>
  )
}

export default BlogArticlePage

export const pageQuery = graphql`
  query getBlogArticle($id: String!) {
    contentfulPageBlogArticle(contentful_id: { eq: $id }) {
      topic {
        name
        contentful_id
      }
      heading
      article {
        raw
      }
      author {
        name
        contentful_id
      }
      featuredImage {
        title
        gatsbyImageData
      }
      createdAt
      relatedArticles {
        contentful_id
        slug
        topic {
          contentful_id
          name
        }
        author {
          name
          contentful_id
        }
        featuredImage {
          title
          gatsbyImageData
        }
        createdAt
        heading
      }
      pageSections {
        contentful_id
        heading
        content {
          raw
        }
        media {
          ... on ContentfulPageSectionTextWithImageImageVideoUploa {
            media {
              title
              gatsbyImageData
              file {
                url
              }
            }
          }
          ... on ContentfulPageSectionTextWithImageYouTubeEmbed {
            youTubeEmbedCode {
              youTubeEmbedCode
            }
          }
        }
        showImageFirst
        imageBlobBackground
        showSignUpButton
      }
      seo {
        title
        titleTemplate {
          extension
        }
        description {
          description
        }
        image {
          fixed {
            src
          }
        }
      }
    }
  }
`
